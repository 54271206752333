import { Routes } from '@angular/router';
import { APP_ROUTES } from './app/shared/enums/app-routes';
import { AuthGuard } from 'app_code/app/shared/services/route-guards/auth-guard.service';
import { AdminGuard } from './app/shared/services/route-guards/admin-guard.service';
import { PasswordResetGuard } from './app/shared/services/route-guards/password-reset-guard.service';
import { MapParamsResolver } from './app/shared/services/resolvers/map-query-params.resolver';

export const MAIN_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./app/ui/map/map.routes').then(r => r.MAP_ROUTES),
    canActivate: [PasswordResetGuard],
    resolve: { mapParams: MapParamsResolver },
  },
  {
    path: APP_ROUTES.SUBSCRIBED_AREAS,
    loadChildren: () => import('./app/ui/subscribed-areas/subscribed-areas.routes').then(r => r.SUBSCRIBED_AREAS_ROUTES),
    canActivate: [AuthGuard]
  },
  {
    path: APP_ROUTES.COLLECTIONS,
    loadChildren: () => import('./app/ui/collections/collections-page/collections-page.routes').then(r => r.COLLECTIONS_PAGE_ROUTES),
    canActivate: [AuthGuard]
  },
  {
    path: APP_ROUTES.TERMS_OF_SERVICE,
    loadChildren: () => import('./app/ui/policies/terms-of-service/terms-of-service.routes').then(r => r.TERMS_OF_SERVICE_ROUTES),
  },
  {
    path: APP_ROUTES.PRIVACY,
    loadChildren: () => import('./app/ui/policies/privacy/privacy.routes').then((r) => r.PRIVACY_ROUTES),
  },
  {
    path: APP_ROUTES.ACTIVATE,
    loadComponent: () => import('./app/ui/activate-account/activate-account.component').then(c => c.ActivateAccountComponent)
  },
  {
    path: APP_ROUTES.ADMIN,
    loadChildren: () => import('./app/ui/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '',
  }
];
