import { Subscription } from 'rxjs';
import { User } from '../../../shared/interfaces/user';
import { Report } from '../../../shared/interfaces/report';
import { ReportService } from '../../../shared/services/report/report.service';
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Log } from 'ng2-logger/browser';

@Component({
  selector: 'shout-all-comment-reports',
  templateUrl: './all-comment-reports.component.html',
  styleUrls: ['./all-comment-reports.component.scss']
})
export class AllCommentReportsComponent implements OnInit, OnDestroy {

  private log = Log.create('AllCommentReportsComponent');

  public user: User;
  public reports: Report[] = new Array<Report>();
  private pageSize: number = 3;
  public page: number = 0;
  
  public canLoadMore = true; //fix for broken ngx-infinite-scroller
  private subscriptions: Subscription = new Subscription();

  constructor(private reportService: ReportService) { }
  
  ngOnInit() { 
    this.loadReports(this.page)
  }  

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public loadMoreReports(): void {
    if((this.reports.length % this.pageSize) > 0 || this.reports.length == 0) {
      this.loadReports(this.page);
    } else {
      this.loadReports(this.page + 1);
    }
  }

  private loadReports(page: number): void {
    this.canLoadMore = false;
    this.subscriptions.add(
      this.reportService.getCommentReportsOfAllUsers(page, this.pageSize)
            .subscribe(
        (data) => {
          this.log.data("loadReports: ", data)

              this.page += 1;
              this.reportService.mergeReports(this.reports, data.content);

              data.last ? this.canLoadMore = false : this.canLoadMore = true;
              if(!data.last) {
                this.loadMoreReports();
              }
        },
        (error) => {
          this.log.er("Error: " + error);
        }
      )
    )
  }

  onScrollDown () {
    this.log.data('onScrollDown!');
    if(this.canLoadMore) {
      this.loadMoreReports();
    }
  }

  public onUp() {
    this.log.data('scrolled up!');
  }
}
