
<div 
    infiniteScroll
    debounce
    [scrollWindow]="false"
    [infiniteScrollContainer]="'.admin-panel__content'"
    [fromRoot]="true"
    [infiniteScrollThrottle]="300"
    (scrolled)="onScrollDown()"
    *ngFor="let reportedUser of reported" 
    class="all-reports__report-container">

    <div class="report-container__header">
        <p class="report-container__report-number">reported {{ reportedUser.numberOfReports }} times</p>
        <p class="report-container__accused-name" (click)="navigateToUser(reportedUser.userId)"> 
            <span class="fa fa-flag report-container__accused-name--spacing element--grey-out"></span>
            {{ reportedUser.username }} 
        </p>
    </div>
</div>