import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom, Injector } from '@angular/core';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import { Log } from 'ng2-logger/browser';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { AdminModule } from './app/ui/admin/admin.module';
import { provideRouter, Router } from '@angular/router';
import { MAIN_ROUTES } from './main.routes';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { InitializationService } from './app/shared/services/login/initialization.service';
import { LOCATION_INITIALIZED } from '@angular/common';
import { JwtToken } from './app/shared/interfaces/jwt-token';
import { AuthInterceptor } from './app/shared/services/auth/auth.interceptor';
import { provideLottieOptions } from 'ngx-lottie';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';

if (environment.production) {
  enableProdMode();
  Log.disableLogs();
}

if (environment.shouldInitSentry) {
  Sentry.init({
    dsn: "https://154f5bdb276c587a56f420130c79ed50@o4507073894088704.ingest.de.sentry.io/4507120265265232",
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/spaceshout\.com\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

export function tokenGetter() {
  let token = localStorage.getItem("id_token");
  return token ? (JSON.parse(token) as JwtToken).token : null;
}

export function initApp(initializationService: InitializationService): () => Promise<any> {
  return () => initializationService.initialize();
}

export function appInitializerFactory(translateService: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    translateService.setDefaultLang('en-US');
    let code: string = translateService.getBrowserLang();
    try {
      switch (code) {
        case "pl":
          await translateService.use(code).toPromise();
          break;
        default:
          await translateService.use("en-US").toPromise();
          break;
      }
    } catch (err) {

    }
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json?v=" + Date.now());
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(MAIN_ROUTES),
    importProvidersFrom([
      AdminModule,
      SocialLoginModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: ["localhost:4200", "localhost:8080", "spaceshout.com"]
        }
      }),
    ]),
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [InitializationService, HttpClient],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, Sentry.TraceService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        lang: 'en',
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('914378287965-g27mahudpsv6rrkabk135gkj9ibklucf.apps.googleusercontent.com', {
              scopes: 'openid profile email',
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('381698495626679', {
              scope: 'email, user_friends, public_profile',
              return_scopes: true,
              enable_profile_selector: true,
              version: "v2.11"
            })
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    provideLottieOptions({
      player: () => import('lottie-web/build/player/lottie_light'),
    }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
  ]
}).catch(err => console.error(err));
