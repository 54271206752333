import { Log } from 'ng2-logger/browser';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CollectionContentDTO, CollectionDTO, CollectionNameUnique } from 'app_code/app/shared/model/collection-dto';

@Injectable({ 
    providedIn: 'root' 
})
export class CollectionService {

    private log = Log.create('CollectionService');

    constructor(private authHttp: HttpClient) { }

    public getCollections(): Observable<CollectionDTO> {
        let page: number = 0;
        let size: number = 0;

        return this.authHttp.get('rest/poi/collections' + '?page=' + page + '&size=' + size)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getCollectionWithPoint(): Observable<CollectionDTO> {
        let page: number = 0;
        let size: number = 0;

        return this.authHttp.post('rest/poi/collections/withPoint', {page, size})
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getFollowedCollections(): Observable<CollectionDTO> {
        let page: number = 0;
        let size: number = 0;

        return this.authHttp.get('rest/poi/collections/followed' + '?page=' + page + '&size=' + size)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getFollowedCollectionWithPoint(): Observable<CollectionDTO> {
        let page: number = 0;
        let size: number = 0;

        return this.authHttp.post('rest/poi/collections/followed/withPoint', {page, size})
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public getCollection(id: number):  Observable<CollectionContentDTO> {
        return this.authHttp.get('rest/poi/collection/' + id)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    getCollectionByName(name: string): Observable<CollectionContentDTO> {
        return this.authHttp.get<CollectionContentDTO>(`rest/poi/collection/url/${name}`);
    }

    public addCollection(collection: CollectionContentDTO): Observable<CollectionContentDTO> {
        return this.authHttp.post('rest/poi/collection', collection)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public updateCollection(collection: CollectionContentDTO): Observable<CollectionContentDTO> {
        const payload = {
            id: collection.id,
            title: collection.title,
            description: collection.description,
            image: collection.image,
            collectionType: collection.collectionType
        };
        return this.authHttp.put('rest/poi/collection', payload)
        .pipe(
            map(res => {
                return res;
            }),
            catchError(this.handleError))
    }

    public deleteCollection(collectionId: number): Observable<void> {
        return this.authHttp.delete('rest/poi/collection/' + collectionId)
        .pipe(
            map((res) => {
                return;
            }),
            catchError((error) => {
                return throwError(error)
            }))
    }

    public subscribeCollection(collectionId: number): Observable<void> {
        return this.authHttp.post(`rest/collection/${collectionId}/subscribe`, {})
        .pipe(
            map((res) => {
                return;
            }),
            catchError((error) => {
                return throwError(error)
            }))
    }

    public unsubscribeCollection(collectionId: number): Observable<void> {
        return this.authHttp.delete(`rest/collection/${collectionId}/unsubscribe`)
        .pipe(
            map((res) => {
                return;
            }),
            catchError((error) => {
                return throwError(error)
            }))
    }

    isCollectionTitleUnique(title: string): Observable<CollectionNameUnique> {
        return this.authHttp.get<CollectionNameUnique>(`rest/collection/check/title/${title}`).pipe(
            catchError((error) => {
                return throwError(error)
            })
        )
    }

    private handleError(error: any): Observable<any> {
        this.log.data(error);
        return throwError(error.message || 'Server error');
    }
}