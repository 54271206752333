import { WindowResizeService } from '../../shared/services/window-resize.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../shared/services/auth/auth.service';
import { User } from '../../shared/interfaces/user';
import { ReportService } from '../../shared/services/report/report.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Log } from 'ng2-logger/browser';

@Component({
  selector: 'shout-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {

  private log = Log.create('AdminComponent');

  public imgQueueLimit: number = 1;
  public user: User;
  public tab: string = "listOfCommentReports";
  public clickedUserId: string;
  public mobileNavigation: boolean = true;

  private subscriptions: Subscription = new Subscription();

  constructor(private authService: AuthService,
              private reportService: ReportService,
              private windowResizeService: WindowResizeService) { }
  
  ngOnInit() {
    this.subscriptions.add(
      this.reportService.usernameClicked.subscribe((id) => {
        this.tab = "userOverview"
        this.clickedUserId = id;
      })
    )
    this.subscribeToWindowResize();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private subscribeToWindowResize(): void {
    this.subscriptions.add(
      this.windowResizeService.windowResized.asObservable().subscribe((event: any) => {
          if(this.mobileNavigation == false && event.target.innerWidth > 576) {
            this.toggleMobileNavigation();
          }
        },
        error => {
          this.log.error("Something went wrong messages: ", error);
        })
    )
  }

  public setTab(tab: string): void {
    this.tab = tab;
  }

  public toggleMobileNavigation(): void {
    this.mobileNavigation = !this.mobileNavigation;
  }
  
  public logout(): void {
    this.authService.logout();
  }
}