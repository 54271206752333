import { Subscription } from 'rxjs';
import { User } from '../../../shared/interfaces/user';
import { UserService } from '../../../shared/services/user/user.service';
import { ReportService } from '../../../shared/services/report/report.service';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Log } from 'ng2-logger/browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'app_code/app/shared/services/toast/toast.service';
import { ToastMessageType } from 'app_code/app/shared/enums/toast-message-type';

@Component({
  selector: 'shout-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss']
})
export class UserOverviewComponent implements OnChanges, OnDestroy {

  private log = Log.create('UserOverviewComponent');

  @Input() userId: string;
  public user: User;
  public reports;
  private pageSize: number = 4;
  public page: number = 0;
  public activeTab = "receivedReports";
  public givenReportsCount: number = 0;
  public receivedReportsCount: number = 0;

  private subscriptions: Subscription = new Subscription();
  
  constructor(private userService: UserService,
              private toastService: ToastService,
              private reportService: ReportService,
              private translate: TranslateService) { }
  

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges() {
    this.loadData();
    this.getReceivedReportsCount();
    this.getGivenReportsCount();
  }

  private loadData(): void {
    this.page = 0;
    this.reports = [];
    this.getUser()
    this.loadCommentReports(this.page)
  }
  
  private getUser(): void {
    this.subscriptions.add(
      this.userService.getUser(this.userId)
      .subscribe(
        (user) => {
          this.log.data("user: ", user);
          this.user = user;
        },
        (error) => {
          this.log.er("Error: " + error);
          this.toastService.open(ToastMessageType.info, 'global.error');
        }
      )
    )
  }
  
  public getReceivedReportsCount(): void {
    this.subscriptions.add(
      this.reportService.getReceivedReportsCountByUserId(this.userId)
        .subscribe(
          (data) => {
            this.log.data("ReceivedReportsCount: ", data);
            this.receivedReportsCount = data[0].numberOfReports + data[1].numberOfReports;
          },
          (error) => {
            this.log.er("Error: " + error);
        }
      )
    )
  }

  public getGivenReportsCount(): void {
    this.subscriptions.add(
      this.reportService.getGivenReportsCountByUserId(this.userId)
        .subscribe(
          (data) => {
            this.log.data("GivenReportsCount: ", data);
            this.givenReportsCount = data[0].numberOfReports + data[1].numberOfReports;
          },
          (error) => {
            this.log.er("Error: " + error);
        }
      )
    )
  }

  public loadMoreReports(): void {
    if((this.reports.content.length % this.pageSize) > 0 || this.reports.content.length == 0) {
      this.loadCommentReports(this.page);
    } else {
      this.loadCommentReports(this.page + 1);
    }
  }

  private loadCommentReports(page: number): void {
    if(this.activeTab == "receivedReportsComments") {
      this.subscriptions.add(
        this.reportService.getReceivedCommentReportsByUserId(this.userId, page, this.pageSize)
        .subscribe(
          (data) => {
            this.log.data("receivedReportsComments: ", data);
            this.reports = data;
            this.reportService.mergeReports(this.reports.content, data.content);
          },
          (error) => {
            this.log.er("Error: " + error);
          }
        )
      )
    } else if(this.activeTab == "givenReportsComments") {
      this.subscriptions.add(
        this.reportService.getGivenCommentReportsByUserId(this.userId, page, this.pageSize)
        .subscribe(
          (data) => {
            this.log.data("givenReportsComments: ", data);
            this.reports = data;
            this.reportService.mergeReports(this.reports.content, data.content);
          },
          (error) => {
            this.log.er("Error: " + error);
          }
        )
      )
    } else if(this.activeTab == "receivedReportsReplies") {
      this.subscriptions.add(
        this.reportService.getReceivedReplyReportsByUserId(this.userId, page, this.pageSize)
        .subscribe(
          (data) => {
            this.log.data("receivedReportsReplies: ", data);
            this.reports = data;
            this.reportService.mergeReports(this.reports.content, data.content);
          },
          (error) => {
            this.log.er("Error: " + error);
          }
        )
      )
    } else if(this.activeTab == "givenReportsReplies") {
      this.subscriptions.add(
        this.reportService.getGivenReplyReportsByUserId(this.userId, page, this.pageSize)
        .subscribe(
          (data) => {
            this.log.data("givenReportsReplies: ", data);
            this.reports = data;
            this.reportService.mergeReports(this.reports.content, data.content);
          },
          (error) => {
            this.log.er("Error: " + error);
          }
        )
      )
    }
  }

  public unbanUser(): void {
    this.subscriptions.add(
      this.reportService.unbanUserById(this.userId)
        .subscribe(
          () => {
            this.log.data("Unanned user of id: " + this.userId);
          },
          (error) => {
            this.log.er("Error: " + error);
          }
        )
      )
  }


  public setTab(tab: string): void {
    this.activeTab = tab;
    this.loadData()
  }

  onScrollDown () {
    this.loadMoreReports();
  }

  onUp() {
    this.log.data('scrolled up!');
  }

}
