<div class="admin-panel">
    <div class="admin-panel__wrapper">
        <div class="admin-panel__navigation">

        <div class="admin-panel__utility-bar bar--sm">
                <div (click)="toggleMobileNavigation()" class="utility-bar__menu-smallscreen">
                    <i class="fa fa-bars"></i>
                    <p>Menu</p>
                </div>

                <div>
                    <button class="utility-bar__btn">
                            <span class="utility-bar__icon-description">Settings</span>
                            <span class="fa fa-cog utility-bar__icon utility-bar__icon--right"></span>
    
                    </button>
                    <button class="utility-bar__btn" (click)="logout()">
                            <span class="utility-bar__icon-description">Sign Out</span>
                            <span class="fa fa-sign-out utility-bar__icon utility-bar__icon--right"></span>
                    </button>
                </div>
            </div>
                    
            <div class="admin-panel__header">
                <p class="admin-panel__title">ADMIN</p>
            </div>

            <div *ngIf="mobileNavigation" class="admin-panel__navigation">
                <div class="navigation__nav-element"
                [ngClass]="{ 'navigation__nav-element--active': tab == 'listOfCommentReports'}" 
                (click)="setTab('listOfCommentReports')">
                    <span class="navigation__tooltip">List of all reported comments sorted by time.</span>
                    <span class="fa fa-flag-o"></span>
                    Reported Comments
                </div>
                <div class="navigation__nav-element"
                [ngClass]="{ 'navigation__nav-element--active': tab == 'reportedUsersByCount'}" 
                (click)="setTab('reportedUsersByCount')">
                    <span class="navigation__tooltip">List of reported users sorted by total number of reports</span>
                    <span class="fa fa-flag-o"></span>
                    Reported total (comments)
                </div>
                <div class="navigation__nav-element"
                [ngClass]="{ 'navigation__nav-element--active': tab == 'reportersUsersByReportsCount'}" 
                (click)="setTab('reportersUsersByReportsCount')">
                <span class="navigation__tooltip">List of users who reported content sorted by total number of reports</span>
                    <span class="fa fa-user-plus"></span>
                    Reporters total (comments)
                </div>

                <hr>
                
                <div class="navigation__nav-element"
                [ngClass]="{ 'navigation__nav-element--active': tab == 'listOfReplyReports'}" 
                (click)="setTab('listOfReplyReports')">
                    <span class="navigation__tooltip">List of all reported replies sorted by time.</span>
                    <span class="fa fa-flag-o"></span>
                    Reported replies
                </div>
                <div class="navigation__nav-element"
                [ngClass]="{ 'navigation__nav-element--active': tab == 'reportedUsersByCountReplies'}" 
                (click)="setTab('reportedUsersByCountReplies')">
                    <span class="navigation__tooltip">List of reported users sorted by total number of reports</span>
                    <span class="fa fa-flag-o"></span>
                    Reported total (replies)
                </div>
                <div class="navigation__nav-element"
                [ngClass]="{ 'navigation__nav-element--active': tab == 'reportersUsersByReportsCountReplies'}" 
                (click)="setTab('reportersUsersByReportsCountReplies')">
                <span class="navigation__tooltip">List of users who reported content sorted by total number of reports</span>
                    <span class="fa fa-user-plus"></span>
                    Reporters total (replies)
                </div>

                <hr>

                <div class="navigation__nav-element"
                [ngClass]="{ 'navigation__nav-element--active': tab == 'reportersByAccurateReports'}" 
                (click)="setTab('reportersByAccurateReports')">
                <span class="navigation__tooltip">List of users who reported content sorted by number of accurate reports - which have been removed</span>
                    <span class="fa fa-user-plus"></span>
                    Reporters accurate
                </div>
                <div class="navigation__nav-element"
                [ngClass]="{ 'navigation__nav-element--active': tab == 'reportersByInaccurateReports'}" 
                (click)="setTab('reportersByInaccurateReports')">
                <span class="navigation__tooltip">List of users who reported content sorted by number of inaccurate reports - which have been kept</span>
                    <span class="fa fa-user-plus"></span>
                    Reporters inaccurate
                </div>
                <div class="navigation__nav-element"
                [ngClass]="{ 'navigation__nav-element--active': tab == 'blockedUsers'}" 
                (click)="setTab('blockedUsers')">
                <span class="navigation__tooltip">List of users who have have been blocked for breaking the rules</span>
                    <span class="fa fa-ban"></span>
                    Blocked Users 
                </div>
            </div>
        </div>
    
        <div class="admin-panel__content">

            <div class="admin-panel__utility-bar bar--lg">
                <button class="utility-bar__btn" (click)="logout()">
                        <span class="utility-bar__icon-description">Sign Out</span>
                        <i class="fa fa-sign-out utility-bar__icon utility-bar__icon--right"></i>
                </button>

                <button class="utility-bar__btn">
                        <span class="utility-bar__icon-description">Settings</span>
                        <i class="fa fa-cog utility-bar__icon utility-bar__icon--right"></i>

                </button>
            </div>

            <div class="admin-panel__content-wrapper">

                <shout-all-comment-reports *ngIf='tab == "listOfCommentReports"'></shout-all-comment-reports>
                <shout-reported-total *ngIf='tab == "reportedUsersByCount"'></shout-reported-total>
                <shout-reporters-total *ngIf='tab == "reportersUsersByReportsCount"'></shout-reporters-total>


                <shout-all-reply-reports *ngIf='tab == "listOfReplyReports"'></shout-all-reply-reports>
                <shout-reported-total-replies *ngIf='tab == "reportedUsersByCountReplies"'></shout-reported-total-replies>
                <shout-reporters-total-replies *ngIf='tab == "reportersUsersByReportsCountReplies"'></shout-reporters-total-replies>

                <shout-reporters-accurate *ngIf='tab == "reportersByAccurateReports"'></shout-reporters-accurate>
                <shout-reporters-inaccurate *ngIf='tab == "reportersByInaccurateReports"'></shout-reporters-inaccurate>

                <shout-blocked-users *ngIf='tab == "blockedUsers"'></shout-blocked-users>
                <shout-user-overview *ngIf='tab == "userOverview"'[userId]="clickedUserId"></shout-user-overview>
                
            </div>
        </div>
    </div>
</div>