
<div class="user-overview__user-card">
    <div class="user-card__header">
        <div class="user-card__user-presentation">
                <div *ngIf="user" class="user-card__image" [ngStyle]="{'background-image': 'url(https://res.cloudinary.com/spaceshout/image/upload/' + user.image + ')'}"></div>
                <p class="user-card__user-name"> 
                    {{user?.username}}
                </p>
        </div>
        <div class="user-card__user-details">

                <div class="user-details__data"> 
                    <p class="user-details__number">{{user?.noOfFriends}}</p>
                    <p class="user-details__description">friends</p>
                </div>

                <div class="user-details__data">
                    <p class="user-details__number">{{ givenReportsCount }}</p>
                    <p class="user-details__description">reports</p>
                </div>

                <div class="user-details__data">
                    <p class="user-details__number">{{ receivedReportsCount }}</p>
                    <p class="user-details__description">times reported</p>
                </div>

                <button (click)="unbanUser()" 
                class="btn btn-outline-primary user-card__decision-btn">
                    UNBAN
                </button>
                <!-- <button class="btn btn-outline-danger user-card__decision-btn">
                    BAN
                </button> -->
        </div>
        
    </div>
</div>

<div class="user-overview__user-card">
    <div class="user-overview__navigation">
        <p (click)="setTab('receivedReportsComments')" [ngClass]="{'active-tab': activeTab == 'receivedReportsComments'}">Received Comments Reports</p>
        <p (click)="setTab('givenReportsComments')" [ngClass]="{'active-tab': activeTab == 'givenReportsComments'}">Given Comments Reports</p>
        <p (click)="setTab('receivedReportsReplies')" [ngClass]="{'active-tab': activeTab == 'receivedReportsReplies'}">Received Replies Reports</p>
        <p (click)="setTab('givenReportsReplies')" [ngClass]="{'active-tab': activeTab == 'givenReportsReplies'}">Given Replies Reports</p>
    </div>
</div>

<div
    *ngIf="activeTab == 'receivedReportsComments'"
    infiniteScroll
    debounce
    [infiniteScrollThrottle]="300"
    [fromRoot]="true"
    (scrolled)="onScrollDown()"
    (scrolledUp)="onUp()">
    <div *ngFor="let report of reports?.content">
        <shout-comment-report
            [blockable]="false"
            [report]="report">
        </shout-comment-report>
    </div>
</div>

<div
    *ngIf="activeTab == 'givenReportsComments'"
    infiniteScroll
    debounce
    [infiniteScrollThrottle]="300"
    [fromRoot]="true"
    (scrolled)="onScrollDown()"
    (scrolledUp)="onUp()">
    <div *ngFor="let report of reports?.content">
        <shout-comment-report
            [blockable]="false"
            [report]="report">
        </shout-comment-report>
    </div>
</div>

<div
    *ngIf="activeTab == 'receivedReportsReplies'"
    infiniteScroll
    debounce
    [infiniteScrollThrottle]="300"
    [fromRoot]="true"
    (scrolled)="onScrollDown()"
    (scrolledUp)="onUp()">
    <div *ngFor="let report of reports?.content">
        <shout-reply-report
            [blockable]="false"
            [report]="report">
        </shout-reply-report>
    </div>
</div>

<div
    *ngIf="activeTab == 'givenReportsReplies'"
    infiniteScroll
    debounce
    [infiniteScrollThrottle]="300"
    [fromRoot]="true"
    (scrolled)="onScrollDown()"
    (scrolledUp)="onUp()">
    <div *ngFor="let report of reports?.content">
        <shout-reply-report
            [blockable]="false"
            [report]="report">
        </shout-reply-report>
    </div>
</div>