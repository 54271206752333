<div class="all-reports__report-container unsolved">
    <div class="report-container__header">
        <p class="report-container__accused-name" (click)="navigateToUser(report.reportedUserId)">
            {{ report.reportedUserUsername }} <span class="fa fa-flag element--grey-out"></span>
        </p>
        <p class="report-container__date">{{ report.created }}</p>
        <p class="report-container__reported-text">{{ report.text }}</p>
        <div class="report-container__image-wrapper">
            <img *ngIf="report.image !== null" class="report-container__bad-image" [src]="'https://res.cloudinary.com/spaceshout/image/upload/' + report.image">
        </div>
    </div>

    <div class="report-container__report-content">
        <div class="report-container__description">
            <p *ngFor="let reportCase of report.reportedComments">
                <span *ngIf="reportCase.reportText !== null">{{reportCase.reportText}}</span>
                <span *ngIf="reportCase.reportText == null">no text</span>
                <span class="report-container__prosecutor-name" (click)="navigateToUser(reportCase.reportingUserId)">{{reportCase.reportingUsername}}</span>
            </p>
        </div>
    </div>

    <div *ngIf="blockable" class="report-container__decision-box">
            <button class="btn btn-outline-danger report-container__decision-btn block" 
                    (click)="banUser($event, report)">
                    <span class="fa fa-thumbs-down"></span>
                    Ban user from Application
            </button>
            <button class="btn btn-outline-warning report-container__decision-btn keep"
                    (click)="deleteReport($event, report.id)">
                    <span class="fa fa-thumbs-up"></span>
                    Remove Content, Don't Ban 
            </button>
            <button class="btn btn-outline-success report-container__decision-btn keep"
                    (click)="keepContent($event, report.id)">
                    <span class="fa fa-thumbs-up"></span>
                    Keep Content 
            </button>
    </div>
</div>