import {
  Injectable,
  ApplicationRef,
  ComponentRef,
  EnvironmentInjector,
  createComponent
} from '@angular/core';
import { MODAL_NAME_TO_STANDALONE_IMPORT_FN } from './modal-components-list';
import { Observable, Subject } from 'rxjs';
import { ModalCloseStatus } from './modal-close-status';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  private modalComponentRef: ComponentRef<any> | null = null;
  private name: string | null = null;

  private modalCloseStatus$ = new Subject<unknown>();
  get modalCloseStatus(): Observable<unknown> {
    return this.modalCloseStatus$.asObservable();
  }

  constructor(
    private appRef: ApplicationRef,
    private environmentInjector: EnvironmentInjector
  ) {}

  async open<T>(name: string, input?: unknown): Promise<void> {
    // If modal already open don't open another one
    if (this.name) {
      return;
    }
    this.name = name;

    // Get component import function
    const importFn = MODAL_NAME_TO_STANDALONE_IMPORT_FN[name];
    if (!importFn) {
      throw new Error(`No standalone component found for key "${name}".`);
    }

    // Load and Create Component
    const component = await importFn();
    const componentRef = createComponent(component, {
      environmentInjector: this.environmentInjector
    });

    if (input) {
      componentRef.setInput('input', input);
    }

    // Attach the view so that Angular recognizes it for change detection
    this.appRef.attachView(componentRef.hostView);
    document.body.appendChild(componentRef.location.nativeElement);

    this.modalComponentRef = componentRef;
  }

  close(output?: ModalCloseStatus): void {
    if (this.modalComponentRef) {
      this.appRef.detachView(this.modalComponentRef.hostView);
      this.modalComponentRef.destroy();
      this.modalComponentRef = null;
    }

    this.modalCloseStatus$.next(output ? output : null);
    this.name = null;
  }
}
