export enum APP_ROUTES {
    MAP = 'map',
    SEARCH = 'search',
    ADD_POINT = 'add-point',
    EDIT_POINT = 'edit-point',
    VIEW_POINTS = 'view-points',
    VIEW_POINTS_DETAILS = 'details',
    ADD_COLLECTION = 'add-collection',
    ADD_SUBSCRIBED_AREA = 'add-subscribed-area',
    PROFILE = 'profile',
    NOTIFICATIONS = 'notifications',
    SUBSCRIBED_AREAS = 'subscribed-areas',
    COLLECTIONS = 'collections',
    TERMS_OF_SERVICE = 'terms-of-service',
    PRIVACY = 'privacy',
    ACTIVATE = 'activate',
    ADMIN = 'admin',
    COLLECTION = 'collection'
}
