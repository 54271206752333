export enum MODAL_COMPONENTS_NAME {
    LoginModal = 'LoginModal',
    RegisterModal = 'RegisterModal',
    PasswordResetRequestModal = 'PasswordResetRequestModal',
    PasswordResetModal = 'PasswordResetModal',
    ConfirmChangesModalModule = 'ConfirmChangesModalModule',
    ReportModalModule = 'ReportModalModule',
    LandingModal = 'LandingModal',
    ShareModal = 'ShareModal'
}

export const MODAL_NAME_TO_STANDALONE_IMPORT_FN = {
    'LoginModal': () => import('../components/login-modal/login-modal.component').then(c => c.LoginModalComponent),
    'ConfirmChangesModalModule': () => import('../components/confirm-changes-modal/confirm-changes-modal.component').then(c => c.ConfirmChangesModalComponent),
    'ReportModalModule': () => import('../components/report-modal/report-modal.component').then(c => c.ReportModalComponent),
    'ShareModal': () => import('../components/share-modal/share-modal.component').then(c => c.ShareModalComponent),
    'RegisterModal': () => import('../components/register-modal/register-modal.component').then(c => c.RegisterModalComponent),
    'PasswordResetRequestModal': () => import('../components/password-reset-request-modal/password-reset-request-modal.component').then(c => c.PasswordResetRequestModalComponent),
    'PasswordResetModal': () => import('../components/password-reset-modal/password-reset-modal.component').then(c => c.PasswordResetModalComponent),
    'LandingModal': () => import('../components/landing-modal/landing-modal.component').then(c => c.LandingModalComponent),
  };