import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { SpaceshoutPriority } from '../../enums/spaceshout-priority';
import { SpaceshoutSize } from '../../enums/spaceshout-size';
import { SvgIconName } from '../svg-icons/svg-icon-name.enum';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { COMMON_MODULES } from '../../constants';
import { SvgIconsComponent } from '../svg-icons/svg-icons.component';

@Component({
  selector: 'spaceshout-button',
  templateUrl: './spaceshout-button.component.html',
  styleUrls: ['./spaceshout-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [...COMMON_MODULES, SvgIconsComponent, LottieComponent]
})
export class SpaceshoutButtonComponent implements AfterContentInit {

  @ContentChild('iconLabel') iconLabel: ElementRef | null = null;
  
  @Input() priority = SpaceshoutPriority.primary;

  @Input() size = SpaceshoutSize.medium;

  @Input() label: string;

  @Input() type?: string;

  @Input() name?: string;

  @Input() disabled = false;

  @Input() loading = false;

  @Input() icon?: SvgIconName;

  @Input() tooltip?: string;

  @Output() clicked = new EventEmitter<void>();

  options: AnimationOptions = {
    path: 'assets/animations/buttonLoader.json',
  };
  hasIconLabel = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.hasIconLabel = !!this.iconLabel;
    this.cd.markForCheck();
  }

  emitClickEvent(ev): void {
    if(!this.disabled) {
      this.clicked.emit(ev);
    } else {
      ev.stopPropagation();
      ev.preventDefault();
    }
  }
}
