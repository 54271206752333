import { Subscription } from 'rxjs';
import { User } from '../../../../shared/interfaces/user';
import { ReportService } from '../../../../shared/services/report/report.service';
import { Component, Input, OnDestroy } from '@angular/core';
import { Log } from 'ng2-logger/browser';

@Component({
  selector: 'shout-reply-report',
  templateUrl: './reply-report.component.html',
  styleUrls: ['./reply-report.component.scss']
})
export class ReplyReportComponent implements OnDestroy{

  private log = Log.create('ReplyReportComponent');

  @Input() report;
  @Input() blockable;
  public user: User;
  private subscriptions: Subscription = new Subscription()

  constructor(private reportService: ReportService) { }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  private banUser(event, report) {
    const userId = report.reportedUserId;
    const poiId = report.poiId || report.poiReplyId;

    this.subscriptions.add(
      this.reportService.banUserById(userId, poiId)
        .subscribe(
          () => {
            this.log.data("BanUser: ", "Banned user of id: " + userId);
            this.moveToAnotherCase(event, ".block");
          },
          (error) => {
            this.log.er("Error: " + error);
          }
        )
    )
  }

  private keepContent(event, reportId: number) {
    let obj = {
      poiId: null,
      replyId: reportId
    }
    this.subscriptions.add(
      this.reportService.keepContentOfId(obj)
        .subscribe(
          (data) => {
            this.log.data("keepContent: ", "Removed report of id  " + reportId + " from the log.");
            this.moveToAnotherCase(event, ".keep");
          },
          (error) => {
            this.log.er("Error: " + error);
          }
        )
    )
  }

  private deleteReport(event, reportId: number) {
    let obj = {
      poiId: null,
      replyId: reportId
    }
    this.subscriptions.add(
      this.reportService.deleteReportById(obj)
        .subscribe(
          (data) => {
            this.log.data("deleteReport: ", "Removed report of id  " + reportId + " from the log.");
            this.moveToAnotherCase(event, ".keep");
          },
          (error) => {
            this.log.er("Error: " + error);
          }
        )
    )
  }

  private moveToAnotherCase(event, btnType: string) {
    let reportCase: HTMLElement = event.target.closest(".all-reports__report-container");
        reportCase.style.opacity = "0.5";
        reportCase.classList.remove("unsolved");
        event.target.closest(btnType).disabled = true;

    let nextReport: HTMLElement = document.querySelector('.unsolved');
    if (nextReport) {
        nextReport.scrollIntoView({ behavior: 'smooth', block: "start" });
    }
  }

  public navigateToUser(id: string) {
    this.reportService.navigateToUser(id)
  }
}
