
<div 
    infiniteScroll
    debounce
    [scrollWindow]="false"
    [infiniteScrollContainer]="'.admin-panel__content'"
    [fromRoot]="true"
    [infiniteScrollThrottle]="300"
    (scrolled)="onScrollDown()"
    *ngFor="let reporter of reporters"
    class="all-reporters__reporters-container">
        <div class="reporters-container__header">
    
            <p class="reporters-container__report-number">submitted {{ reporter.reportScore }} accurate reports</p>
            <p class="reporters-container__reporter-name" (click)="navigateToUser(reporter.userId)"> 
                <span class="fa fa-user-plus reporters-container__reporter-name--spacing element--grey-out"></span>
                {{ reporter.username }} 
            </p>
        </div>
</div>
    