
  <shout-comment-report
    infiniteScroll
    debounce
    [scrollWindow]="false"
    [infiniteScrollContainer]="'.admin-panel__content'"
    [fromRoot]="true"
    [infiniteScrollThrottle]="300"
    [infiniteScrollDistance]="3"
    (scrolled)="onScrollDown()"
    (scrolledUp)="onUp()"
      *ngFor="let report of reports"
      [blockable]="true"
      [report]="report">
  </shout-comment-report>