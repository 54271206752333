import { NgModule } from '@angular/core';
import { AdminComponent } from './admin.component';
import { AllCommentReportsComponent } from './all-comment-reports/all-comment-reports.component';
import { CommentReportComponent } from './all-comment-reports/comment-report/comment-report.component';
import { AllReplyReportsComponent } from './all-reply-reports/all-reply-reports.component';
import { ReplyReportComponent } from './all-reply-reports/reply-report/reply-report.component';
import { ReportedTotalRepliesComponent } from './reported-total-replies/reported-total-replies.component';
import { ReportedTotalComponent } from './reported-total/reported-total.component';
import { ReportersTotalComponent } from './reporters-total/reporters-total.component';
import { ReportersAccurateComponent } from './reporters-accurate/reporters-accurate.component';
import { ReportersTotalRepliesComponent } from './reporters-total-replies/reporters-total-replies.component';
import { ReportersInaccurateComponent } from './reporters-inaccurate/reporters-inaccurate.component';
import { UserOverviewComponent } from './user-overview/user-overview.component';
import { BlockedUsersComponent } from './blocked-users/blocked-users.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent
  },
];

@NgModule({
  declarations: [
    AdminComponent,
    AllCommentReportsComponent,
    CommentReportComponent,
    AllReplyReportsComponent,
    ReplyReportComponent,
    ReportedTotalComponent,
    ReportedTotalRepliesComponent,
    ReportersTotalComponent,
    ReportersTotalRepliesComponent,
    ReportersAccurateComponent,
    ReportersInaccurateComponent,
    BlockedUsersComponent,
    UserOverviewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InfiniteScrollModule,
    RouterModule.forChild(routes)
  ],
  providers: [

  ]
})
export class AdminModule { }
