import { Subscription } from 'rxjs';
import { User } from '../../../shared/interfaces/user';
import { ReportService } from '../../../shared/services/report/report.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Log } from 'ng2-logger/browser';

@Component({
  selector: 'shout-reporters-inaccurate',
  templateUrl: './reporters-inaccurate.component.html',
  styleUrls: ['./reporters-inaccurate.component.scss']
})
export class ReportersInaccurateComponent implements OnInit, OnDestroy {

  private log = Log.create('ReportersInaccurateComponent');

  public userId: string;
  public user: User;
  public reporters = new Array();
  private pageSize: number = 5;
  private page: number = 0;
  
  public canLoadMore = true; //fix for broken ngx-infinite-scroller
  private subscriptions: Subscription = new Subscription();

  constructor(private reportService: ReportService) { }
  
  ngOnInit() { 
    this.loadUsers(this.page);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public loadMoreUsers(): void {
    if((this.reporters.length % this.pageSize) > 0 || this.reporters.length == 0) {
      this.loadUsers(this.page);
    } else {
      this.loadUsers(this.page + 1);
    }
  }

  private loadUsers(page: number): void {
    this.canLoadMore = false;
    this.subscriptions.add(
      this.reportService.getReportersUsersOrderedByInccurateReportScore(page, this.pageSize)
        .subscribe(
          (data) => {
            this.log.data("report: ", data);

            this.page += 1;
            this.reportService.mergeUsersList(this.reporters, data.content);

            data.last ? this.canLoadMore = false : this.canLoadMore = true;
            if(!data.last) {
              this.loadMoreUsers();
            }
          },
          (error) => {
            this.log.er("Error: " + error);
          }
        )
      )
  }

  public navigateToUser(id: string): void {
    this.reportService.navigateToUser(id)
  }

  onScrollDown () {
    this.log.data('onScrollDown!');
    if(this.canLoadMore){
      this.loadMoreUsers();
    }
  }
}
