
<div 
    infiniteScroll
    debounce
    [scrollWindow]="false"
    [infiniteScrollContainer]="'.admin-panel__content'"
    [fromRoot]="true"
    [infiniteScrollThrottle]="300"
    (scrolled)="onScrollDown()"
    *ngFor="let blockedUser of blockedUsers"
    class="all-blocked__blocked-container">
        <div class="blocked-container__header">
            <p class="blocked-container__blocked-number">times blocked {{ blockedUser.blocks }} times</p>
            <p class="blocked-container__blocked-name" (click)="navigateToUser(blockedUser.userId)"> 
                <span class="fa fa-ban blocked-container__blocked-name--spacing element--grey-out"></span>
                {{ blockedUser.username }} 
            </p>
        </div>
</div>
    