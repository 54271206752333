import { Injectable }     from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot }    from '@angular/router';

import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {

constructor(private authService: AuthService,
            private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isUserTypeAdmin()) {
      return true
    }
    
    this.router.navigate([''], {skipLocationChange: true});
    this.authService.redirectUrl = state.url.split("?")[0];
    this.authService.redirectParams = route.queryParamMap;
    this.router.navigate(['']);
    return false;
  }
}