import { Subscription } from 'rxjs';
import { User } from '../../../shared/interfaces/user';
import { ReportService } from '../../../shared/services/report/report.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Log } from 'ng2-logger/browser';

@Component({
  selector: 'shout-reported-total',
  templateUrl: './reported-total.component.html',
  styleUrls: ['./reported-total.component.scss']
})
export class ReportedTotalComponent implements OnInit, OnDestroy {

  private log = Log.create('ReportedTotalComponent');

  public userId: string;
  public user: User;
  public reported = new Array();
  private pageSize: number = 5;
  private page: number = 0;
  
  public canLoadMore = true; //fix for broken ngx-infinite-scroller
  private subscriptions: Subscription = new Subscription();

  constructor(private reportService: ReportService) { }
  
  ngOnInit() { 
    this.loadUsers(this.page);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public loadMoreUsers(): void {
    if((this.reported.length % this.pageSize) > 0 || this.reported.length == 0) {
      this.loadUsers(this.page);
    } else {
      this.loadUsers(this.page + 1);
    }
  }

  private loadUsers(page: number): void {
    this.canLoadMore = false;
    
      this.subscriptions.add(
        this.reportService.getCommentReportedUsersOrderedByReportCount(page, this.pageSize)
          .subscribe(
            (data) => {
              this.log.data("report: ", data);

              this.page = data.number;
              this.reportService.mergeUsersList(this.reported, data.content);
              
              data.last ? this.canLoadMore = false : this.canLoadMore = true;
              if(!data.last) {
                this.loadMoreUsers();
              }
            },
            (error) => {
              this.log.er("Error: " + error);
            }
          )
      )
  }

  public navigateToUser(id: string): void {
    this.reportService.navigateToUser(id)
  }

  onScrollDown () {
    this.log.data('onScrollDown!');
    if(this.canLoadMore){
      this.loadMoreUsers();
    }
  }

}
